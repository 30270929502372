export const TRANSACTIONS_STATUSES = [
  "PENDING",
  "SETTLED",
  "RELEASED",
] as const;

export const TRANSACTIONS_RELEASE_REASON_CODES = [
  "INVALID_PAYMENT_DEVICE",
  "PAYMENT_CANCELLED",
  "NON_SUFFICIENT_FUNDS",
  "DISABLED_ACCOUNT",
  "PAYMENT_RECALLED",
  "INSTITUTION_IN_DEFAULT",
  "ACCOUNT_FROZEN",
  "NSF",
  "missedPayment",
] as const;

export const TRANSACTIONS_ADJUSTMENT_REASON_CODES = [
  "PAYOUT_COMPLETED",
  "FEE_CREDIT",
  "BACK_DATED_PAYOUT",
  "MANUAL_ADJUSTMENT",
] as const;

export const TRANSACTIONS_ASSET_TYPES = ["CASH"] as const;

export const TRANSACTIONS_CURRENCIES = ["CAD"] as const;

export const TRANSACTIONS_TYPES = ["DEBIT", "CREDIT"] as const;
